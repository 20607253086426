<template>
    <div>
        <div class="modal-backdrop fade show"></div>
        <!-- Custom field modal -->
        <div class="modal fade custom-fields show" id="new-pipeline-template" style="display: block;">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <form action="" @submit.prevent="">
                    <div class="modal-content">
<!--                        v-on-click-away="close"-->
                        <div class="modal-header">
                            <h4 class="modal-title text-uppercase">{{ $t("Interview schedule") }}</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                    @click.prevent="close()">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">

                                        <label>{{ $t(`Start Time`) }}</label>

                                        <date-picker
                                                v-model="form.start_time"
                                                :placeholder="$t('mm/dd/yyyy')"
                                                autocomplete="off"
                                                :config="datePickerOptions"
                                        ></date-picker>

                                        <div class="error-message" v-text="errors.start_time"></div>

                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{ $t(`Duration`) }}</label>
                                        <select class="form-control" v-model="form.duration" style="height: 50px;">
                                            <option value="">{{ $t(`Select duration`) }}</option>
                                            <option v-for="minutes in durations" :value="minutes">{{ minutes }}
                                                Minutes
                                            </option>
                                        </select>

                                        <div class="error-message" v-text="errors.duration"></div>

                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">

                                        <label>{{ $t(`Time zone`) }}</label>

                                        <multiselect
                                                id="salary_type"
                                                v-model="timezone"
                                                :options="timeZones"
                                                :placeholder="$t('Select timezone')"
                                                track-by="time_zone"
                                                label="time_zone"
                                                name="time-zone"
                                        ></multiselect>
                                        <div class="error-message" v-text="$t(errors.timezone)"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-4 mb-2 d-flex">
                                <a href="javascript:void(0)" @click="setMeetingClient('meet')"
                                   class="meeting-method-icon" :class="interViewClient=='meet'?'active': ''">
                                    <img src="/img/meet.png" alt="">
                                </a>
                                <a href="javascript:void(0)" @click="setMeetingClient('zoom')"
                                   class="meeting-method-icon" :class="interViewClient=='zoom'?'active': ''">
                                    <img src="/img/zoom.png" alt="">
                                </a>
                                <a href="javascript:void(0)" @click="setMeetingClient('other')"
                                   class="meeting-method-icon text-center" style="padding-left: 20px!important;" :class="interViewClient=='other'?'active': ''">
                                    Others
                                </a>
                            </div>
                            <div class="row"  v-if="interViewClient=='other'">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <textarea class="form-control" rows="5" v-model="form.instructions"></textarea>
                                        <div class="error-message" v-text="$t(errors.instructions)"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="error-message" v-text="$t(errors.meetingClient)"></div>
                        </div>
                        <div class="modal-footer d-flex justify-content-between">
                            <button class="button semi-button-info" data-dismiss="modal" @click.prevent="close">
                                {{ $t(`Back`) }}
                            </button>
                            <submit-button :click="createInterview" :loading="isLoading">{{ $t(`Create Interview`) }}
                            </submit-button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import client from "../../../app/api/Client";
import Multiselect from 'vue-multiselect';
import moment from "moment";
import {datePickerIcons} from "../../../config/options";
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import {mapState} from "vuex";

export default {
    components: {
        Multiselect,
        datePicker
    },
    props: ['applicantIds', 'jobId'],
    data() {
        return {
            form: {
                start_time: '',
                duration: '',
                applicant: this.applicantIds,
                meetingClient: '',
                jobId: this.jobId,
                instructions: '',
            },
            errors: {
                start_time: null,
                duration: '',
                meetingClient: '',
                timezone: ''
            },
            isLoading: false,
            durations: [30, 60, 120],
            timeZones: [],
            timezone: {
                time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }

        }
    },
    methods: {
        close() {
            this.$emit('remote_interview_closed');
        },
        isValid() {
            if (this.form.start_time == '') {
                this.errors.start_time = 'Please select a valid time';
                return false;
            }

            if (this.form.meetingClient == '') {
                this.errors.meetingClient = 'Please select meeting client';
                return false;
            }
            if (this.timezone == null) {
                this.errors.timezone = 'Please select timezone';
                return false;
            }

            if (this.form.duration == '') {
                this.errors.duration = 'Please select meeting duration';
                return false;
            }

            if (this.form.meetingClient == 'other' && this.form.instructions == '') {
                this.errors.instructions = 'Please provide meeting instructions.';
                return false;
            }

            return true;
        },
        createInterview() {
            this.errors = {};
            if (this.isValid()) {
                this.isLoading = true;
                this.form.timezone = this.timezone.time_zone;
                this.form.last_applicant = this.pageState.defaultBoard;
                if(this.pageState.sortBy !== '') {
                    this.form.last_applicant = true;
                }

                client().post(`/job/${this.jobId}/pipeline/remote-interview`, this.form).then(({data: {data}}) => {
                    if (data.setting_url) {
                        this.form.meetingClient = '';
                        this.errors.meetingClient = 'App credentials not set.';
                        this.$toast.warning('Please set app credentials first');
                        setTimeout(function () {
                            window.open(data.setting_url);
                        }, 2000);
                    } else if (data.redirect_url) {
                        this.form.meetingClient = '';
                        this.errors.meetingClient = 'Please select & login with meeting client.';
                        this.$toast.warning('Please select & login with meeting client.');
                    } else {
                        this.$toast.success(this.$t("Pipeline updated"));
                        this.$emit('remote_interview_moved')
                    }
                }).catch(({response: {data, status}}) => {
                    this.$toast.error(data.message);
                }).finally(res => {
                    this.isLoading = false;
                });
            }
        },
        setMeetingClient(meetingClient) {
            this.errors = {};
            this.form.meetingClient = meetingClient;
            if (meetingClient !== 'other') {
                if (this.isValid()) {
                    this.isLoading = true;
                    client().post(`/job/${this.jobId}/pipeline/remote-interview-client`, this.form).then(({data: {data}}) => {
                        if (data.setting_url) {
                            this.$toast.warning('Please set app credentials first');
                            setTimeout(function () {
                                window.open(data.setting_url);
                            }, 2000);
                        } else if (data.redirect_url) {
                            let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=800,left=100,top=100`;
                            window.open(data.redirect_url, 'Client login', params);
                        } else {
                            this.$toast.errors(this.$t(data.message));
                        }
                    }).catch(({response: {data, status}}) => {
                        this.$toast.error(data.message);
                    }).finally(res => {
                        this.isLoading = false;
                    });
                } else {
                    this.form.meetingClient = '';
                }
            }
        },
        async getTimeZones() {
            let {data: {data}} = await client().get('/timezones');
            this.timeZones = data;
        },
    },
    computed: {
        ...mapState(['pageState']),
        interViewClient() {
            return this.form.meetingClient;
        },
        datePickerOptions() {
            return {
                format: 'MM/DD/YYYY hh:mm A',
                minDate: moment().format('MM/DD/YYYY'),
                icons: datePickerIcons,
                useCurrent: false
            }
        }
    },
    mounted() {
        this.getTimeZones();
        // let today = new Date();
        // let tomorrow = new Date(today);
        // tomorrow.setDate(tomorrow.getDate() + 1);
        this.form.start_time = moment().add(1, 'day');
    }
};
</script>
<style scoped>
.modal-body {
    overflow-y: unset !important;
}
</style>
